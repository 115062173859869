import {FC, useState, useEffect} from 'react';
import {useLoaderData} from '@remix-run/react';
import {ArrowLeft} from 'lucide-react';
import {Modal, TextInput, Label, Radio, ToggleSwitch} from 'flowbite-react';

import {Heading} from '~/modules/Common/components/Heading';
import {Button} from '~/modules/Common/components/Button';
import {Text} from '~/modules/Common/components/Text';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';
import {PortalSpinner} from '~/modules/Common/components/PortalSpinner';
import {TimedToast} from '~/modules/Common/components/TimedToast';

interface CreateAffiliateLinkModalProps {
  closeModal: () => void;
  productId: string;
  isAffiliateProduct?: boolean;
}

interface CreateAffiliateLinkModalBodyProps
  extends CreateAffiliateLinkModalProps {}

interface TrackingLinkSourceProps extends CreateAffiliateLinkModalProps {}

interface AmazonTrackingLink {
  id: string;
  slug: string;
  url: string;
  shortlinkId: string;
  linkType: string;
  primaryTrackingId: string;
  subTrackingId: string;
  amzAssociateId: string;
  sourceName: string;
}

interface APIStatus {
  success: boolean | null;
  loading: boolean;
}

const FormInputIds = {
  'amazon-associate-id': 'amazon-associate-id',
  'amazon-associate-check': 'amazon-associate-check',
  'primary-tracking-id': 'primary-tracking-id',
  'sub-tracking-id': 'sub-tracking-id',
  'internal-display-name': 'internal-display-name',
};

const ModalViewType = {
  viewTrackingLink: 'viewTrackingLink',
  createTrackingLink: 'createTracking',
};

const TrackingLinkSource: FC<TrackingLinkSourceProps> = (
  props: TrackingLinkSourceProps,
) => {
  const {productId, closeModal} = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [linkSource, setLinkSource] = useState<AmazonTrackingLink[]>([]);
  const [selectedLinkId, setSelectedLinkId] = useState<string>('');
  const [apiStatus, setApiStatus] = useState<APIStatus>();
  const actionLink = useExpertLink('/my/api/amazonTrackingLinkSource');
  const generateActionLink = useExpertLink('/my/api/generateLink');

  useEffect(() => {
    const getAmazonTrackingLink = async () => {
      try {
        setIsLoading(true);
        const response: any = await fetch(actionLink);
        const parsedResponse = await response.json();
        setLinkSource(parsedResponse.result);
        setIsLoading(false);
      } catch (err) {
        setLinkSource([]);
        setIsLoading(false);
      }
    };
    getAmazonTrackingLink();
  }, [actionLink]);

  const handleTrackingLink = (value: string) => {
    setSelectedLinkId(value);
  };

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    if (apiStatus?.success) {
      timerId = setTimeout(() => {
        closeModal();
      }, 1000);
    }
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [apiStatus?.success, closeModal]);

  const handleCreateLink = async () => {
    setApiStatus({loading: true, success: null});
    const selectedlinkObj = linkSource.find(
      (item) => item.id === selectedLinkId,
    );
    const response = await fetch(generateActionLink, {
      method: 'post',
      body: JSON.stringify({
        productId,
        primaryTrackingId: selectedlinkObj?.primaryTrackingId,
        subTrackingId: selectedlinkObj?.subTrackingId,
        amzAssociateTag: selectedlinkObj?.amzAssociateId,
        sourceName: selectedlinkObj?.sourceName,
      }),
    });
    const result: any = await response.json();
    setApiStatus({loading: false, success: result.success});
  };

  return (
    <>
      {isLoading && (
        <div className="flex justify-center items-center h-[90%]">
          <PortalSpinner />
        </div>
      )}
      <div className="overflow-auto">
        {!isLoading &&
          linkSource.map((item) => (
            <div key={item.id} id={item.id} className="border-b border-inherit">
              <div className="flex justify-between mx-4 my-4 items-center">
                <div className="flex flex-col justify-center">
                  <Text size="subtext">{item.sourceName}</Text>
                  <Text className="text-xs text-gray-500">
                    {item.primaryTrackingId}{' '}
                    {item.subTrackingId ? `|${item.subTrackingId}` : ''}
                  </Text>
                </div>
                <Radio
                  checked={item.id === selectedLinkId}
                  value={item.id}
                  onChange={(e) => handleTrackingLink(e.target.value)}
                  theme={{
                    root: {
                      base: 'h-4 w-4 border border-gray-300 focus:ring-0 dark:border-gray-900 dark:bg-gray-900 dark:focus:bg-gray-900 dark:focus:ring-gray-900 text-gray-900',
                    },
                  }}
                />
              </div>
            </div>
          ))}
      </div>
      {!isLoading && (
        <div className="self-center" style={{width: 'calc(100% - 24px)'}}>
          <Button
            type="submit"
            className="w-full"
            onClick={handleCreateLink}
            isLoading={apiStatus?.loading}
          >
            Create New Link
          </Button>
        </div>
      )}
      <TimedToast
        message={
          apiStatus?.success
            ? `New Link created succesfully`
            : `Failed to create link`
        }
        isSuccess={apiStatus?.success === true || apiStatus?.success === false}
        status={apiStatus?.success === true ? 'success' : 'error'}
      />
    </>
  );
};

const CreateTrackingLink: FC<CreateAffiliateLinkModalBodyProps> = (
  props: CreateAffiliateLinkModalBodyProps,
) => {
  const {productId, closeModal, isAffiliateProduct} = props;
  const data = useLoaderData();
  const [apiStatus, setApiStatus] = useState<APIStatus>();
  const [isAmazonIdVisible, setIsAmazonIdVisible] = useState(false);
  const [formValues, setFormValues] = useState({
    [FormInputIds['amazon-associate-id']]: '' as string,
    [FormInputIds['primary-tracking-id']]: '' as string,
    [FormInputIds['sub-tracking-id']]: '' as string,
    [FormInputIds['internal-display-name']]: '' as string,
  });
  const [errState, setErrState] = useState({
    [FormInputIds['amazon-associate-id']]: false,
    [FormInputIds['primary-tracking-id']]: false,
    [FormInputIds['sub-tracking-id']]: false,
  });
  const actionLink = useExpertLink('/my/api/generateLink');
  const amazonSettingLink = useExpertLink('/my/amazonsettings');

  const isValidData = !Object.values(errState).find((item) => item);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setApiStatus({loading: true, success: null});
    const response = await fetch(actionLink, {
      method: 'post',
      body: JSON.stringify({
        productId,
        primaryTrackingId: formValues[FormInputIds['primary-tracking-id']],
        subTrackingId: formValues[FormInputIds['sub-tracking-id']],
        amzAssociateTag: isAmazonIdVisible
          ? formValues[FormInputIds['amazon-associate-id']]
          : null,
        sourceName: formValues[FormInputIds['internal-display-name']],
      }),
    });
    const result: any = await response.json();
    setApiStatus({loading: false, success: result.success});
  };

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    if (apiStatus?.success) {
      timerId = setTimeout(() => {
        closeModal();
      }, 1000);
    }
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [apiStatus?.success, closeModal]);

  useEffect(() => {
    if (isAmazonIdVisible && !formValues[FormInputIds['amazon-associate-id']]) {
      setErrState((prev) => ({
        ...prev,
        [FormInputIds['amazon-associate-id']]: true,
      }));
    } else if (!isAmazonIdVisible) {
      setErrState((prev) => ({
        ...prev,
        [FormInputIds['amazon-associate-id']]: false,
      }));
    }
  }, [isAmazonIdVisible, formValues]);

  const handleChange = (event: any) => {
    const regex = /^[a-zA-Z0-9-]{1,30}$/;
    const {id, value} = event.target;
    if (FormInputIds['internal-display-name'] !== id) {
      const result = value.match(regex);
      setErrState((prev) => ({...prev, [id]: !result?.length}));
    }
    setFormValues((prev) => ({...prev, [id]: value}));
  };

  const handleToggleSwitch = () => {
    setIsAmazonIdVisible((prev) => !prev);
  };

  return (
    <>
      {/* {!data.expert?.amzAssociateTag && (
        <Text className="text-black text-left my-6" size="subtext" as="p">
          <a
            href={amazonSettingLink}
            className="text-purple-800 underline underline-offset-4"
          >
            Add your Amazon Associate ID
          </a>
          &nbsp; to receive additional commissions.
        </Text>
      )} */}
      <form
        className="flex max-w-md flex-col gap-4 relative"
        onSubmit={handleSubmit}
        style={isAmazonIdVisible ? {height: '524px'} : {height: '408px'}}
      >
        {isAffiliateProduct && (
          <div className="flex justify-between items-center">
            <div className="text-sm font-medium">
              Use your default Associate ID
            </div>
            <ToggleSwitch
              checked={!isAmazonIdVisible}
              label=""
              onChange={handleToggleSwitch}
            />
          </div>
        )}
        {isAmazonIdVisible && (
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor={FormInputIds['amazon-associate-id']}
                value="Amazon Associate ID"
              />
            </div>
            <TextInput
              id={FormInputIds['amazon-associate-id']}
              type="text"
              required
              onChange={handleChange}
              value={formValues[FormInputIds['amazon-associate-id']]}
              color={
                errState[FormInputIds['amazon-associate-id']] ? 'failure' : ''
              }
              helperText={
                <>
                  {errState[FormInputIds['amazon-associate-id']] ? (
                    <span>
                      Please enter a value (or toggle back to use your default
                      Amazon ID)
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              }
            />
          </div>
        )}
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor={FormInputIds['primary-tracking-id']}
              value="Primary Tracking ID"
            />
          </div>
          <TextInput
            id={FormInputIds['primary-tracking-id']}
            type="text"
            required
            onChange={handleChange}
            value={formValues[FormInputIds['primary-tracking-id']]}
            color={
              errState[FormInputIds['primary-tracking-id']] ? 'failure' : ''
            }
            helperText={
              <>
                {errState[FormInputIds['primary-tracking-id']] ? (
                  <span>Please enter a valid tracking id</span>
                ) : (
                  <></>
                )}
              </>
            }
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor={FormInputIds['sub-tracking-id']}
              value="Sub Tracking ID (Optional)"
            />
          </div>
          <TextInput
            id={FormInputIds['sub-tracking-id']}
            type="text"
            onChange={handleChange}
            value={formValues[FormInputIds['sub-tracking-id']]}
            color={errState[FormInputIds['sub-tracking-id']] ? 'failure' : ''}
            helperText={
              <>
                {errState[FormInputIds['sub-tracking-id']] ? (
                  <span>Please enter a valid sub tracking id</span>
                ) : (
                  <></>
                )}
              </>
            }
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor={FormInputIds['internal-display-name']}
              value="Internal Display Name (Optional)"
            />
          </div>
          <TextInput
            id={FormInputIds['internal-display-name']}
            type="text"
            onChange={handleChange}
            value={formValues[FormInputIds['internal-display-name']]}
          />
        </div>
        <div className="absolute bottom-0 w-full">
          <Button
            type="submit"
            className="w-full"
            isLoading={apiStatus?.loading}
            disabled={apiStatus?.loading || !isValidData}
          >
            Create New Link
          </Button>
        </div>
      </form>
      <TimedToast
        message={
          apiStatus?.success
            ? `New Link created succesfully`
            : `Failed to create link`
        }
        isSuccess={apiStatus?.success === true || apiStatus?.success === false}
        status={apiStatus?.success === true ? 'success' : 'error'}
      />
    </>
  );
};

const CreateAmazonAffiliateLinkModal: FC<CreateAffiliateLinkModalProps> = (
  props: CreateAffiliateLinkModalProps,
) => {
  const {closeModal, productId, isAffiliateProduct} = props;
  const [viewType, setViewType] = useState<string>(
    ModalViewType.createTrackingLink,
  );

  const handleModalVisibility = () => {
    closeModal();
  };

  const handleView = () => {
    setViewType(ModalViewType.viewTrackingLink);
  };

  const handleBack = () => {
    setViewType(ModalViewType.createTrackingLink);
  };

  return (
    <Modal
      show
      size={'lg'}
      className="h-screen z-[200]"
      onClose={handleModalVisibility}
    >
      <Modal.Header>
        <Heading
          size="headlineSm"
          as="span"
          className="flex items-center gap-4"
        >
          {viewType === ModalViewType.viewTrackingLink && (
            <ArrowLeft size={16} onClick={handleBack} />
          )}
          {viewType === ModalViewType.createTrackingLink
            ? 'Create New Link'
            : 'Select Existing Source'}
        </Heading>
      </Modal.Header>
      {viewType === ModalViewType.createTrackingLink && (
        <Modal.Body className="min-h-[510px] overflow-auto">
          <Button color="light" className="mb-2 w-full" onClick={handleView}>
            Select from Existing Source
          </Button>
          <CreateTrackingLink
            productId={productId}
            closeModal={closeModal}
            isAffiliateProduct={isAffiliateProduct}
          />
        </Modal.Body>
      )}
      {viewType === ModalViewType.viewTrackingLink && (
        <Modal.Body className="h-[610px] !p-0 !pb-4 flex flex-col justify-between gap-6">
          <TrackingLinkSource productId={productId} closeModal={closeModal} />
        </Modal.Body>
      )}
    </Modal>
  );
};

export default CreateAmazonAffiliateLinkModal;
