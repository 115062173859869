import {CommissionAmountType, DiscountAmountType, Media} from '~/gql/generated';

import {NavItem} from './types';
import {FetcherWithComponents, Params} from '@remix-run/react';
import {Media as ShopifyMedia} from '@shopify/hydrogen/storefront-api-types';
import {formatPrice} from '../Product/utils/productUtils';

const validNonPublic = [
  'CONTENT_API_ENDPOINT',
  'CONTENT_API_KEY',
  'NODE_ENV',
  'AUTH0_DOMAIN',
  'AUTH0_LOGOUT_URL',
  'AUTH0_EXPERT_CLIENT_ID',
  'AUTH0_BRAND_CLIENT_ID',
  'AUTH0_AUDIENCE',
];
export function getPublicEnv(env: Env) {
  return Object.keys(env).reduce((res, key) => {
    if (key.startsWith('PUBLIC_') || validNonPublic.includes(key)) {
      res[key] = env[key];
    }
    return res;
  }, {} as Env);
}

export function getLoaderExpertName(params: Params, request: Request) {
  return params.expertName || getExpertSubdomain(request);
}

export function getExpertSubdomain(request: Request) {
  const domain = request.url.split('://')?.[1];
  if (domain.startsWith('localhost')) {
    return '';
  }
  return domain.split('.')[0];
}

export function getExpertDomain(request: Request) {
  return request.url.split('://')?.[1].split('/')[0];
}

export function getIsVideo(src: string) {
  const extension = (src || '').split('.').pop() || '';
  return ['m4v', 'avi', 'mpg', 'mp4'].includes(extension);
}

export function getMediaProps(media?: Media | ShopifyMedia) {
  let isVideo = false;
  let src = '';
  let alt = '';
  if (!media) return {isVideo, src, alt, isExpert: false};
  const isExpert = !('mediaContentType' in media);

  if (!isExpert) {
    isVideo = media.mediaContentType === 'VIDEO';
    // @ts-expect-error
    src = isVideo ? media.sources[0].url : media.image.url;
  } else {
    isVideo = getIsVideo(media.src);
    src = media.src;
    alt = media.alt;
  }

  return {
    isVideo,
    src: src.replace('http://', 'https://'),
    alt,
    isExpert,
  };
}

export function stripNonNumericCharacters(value: string | number) {
  return (`${value}` || '0').replace(/[^0-9.]/g, '');
}

export function formatCommission(value: string | number) {
  value = stripNonNumericCharacters(value);
  return parseFloat(Math.min(parseFloat(value ? value : 0), 100).toFixed(2));
}

export function formatCommissionValue(
  value: string,
  type: CommissionAmountType,
) {
  if (type === CommissionAmountType.Flat) {
    return formatPrice(value);
  }

  return `${roundIfEndingInZero(value || '0')}%`;
}

export function formatDiscountValue(value: string, type: DiscountAmountType) {
  if (type === DiscountAmountType.Flat) {
    return formatPrice(value, 2, true);
  }

  return `${roundIfEndingInZero(value || '0')}%`;
}

function roundIfEndingInZero(value: string) {
  if (value === '0') {
    return 0;
  }
  return value.endsWith('.00') ? value.slice(0, -3) : roundToTwoDecimals(value);
}

function roundToTwoDecimals(value: string) {
  return parseFloat(parseFloat(value).toFixed(2));
}

export function getPageFromRequest(request: Request) {
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);
  const page = searchParams.get('page');
  let pageInt = 1;
  if (page !== null && page !== undefined) {
    const parsed = parseInt(page, 10);
    if (!isNaN(parsed)) {
      pageInt = parsed;
    }
  }

  return pageInt;
}

export function getTimeAgo(date: string) {
  const timeAgo = new Date(date).getTime();
  const now = new Date().getTime();
  const diff = now - timeAgo;
  const minutes = Math.floor(diff / 1000 / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(weeks / 4);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  }

  if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  }

  if (weeks > 0) {
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  }

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }

  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }

  return 'Just now';
}

export function isValidUrl(url: string, contains?: string) {
  if (!url) return true;
  try {
    new URL(url);
    return url.includes(contains || '');
  } catch (e) {
    return false;
  }
}

export function getDomainFromLink(link: string) {
  try {
    const url = new URL(link || '');
    return url.hostname.replace('.pxf.io', '.com');
  } catch (e) {
    return '';
  }
}

export function getPageStartAndEnd(page?: number, limit?: number) {
  if (!page || !limit) return {start: 0, end: Infinity};
  const start = (page - 1) * limit;
  const end = page * limit;
  return {start, end};
}

export function getIsNavItemActive(active: string, item: NavItem) {
  active = `/${active
    .replaceAll('.', '/')
    .replace('/($brandId)', '')
    .replace('/($expertName)', '')
    .replace('/($tab)', '')}`;
  return (
    active === `/routes${item.to}` ||
    active === `/routes${item.altMatch}` ||
    item.children?.some((c) => active === `/routes${c.to}`)
  );
}

export function getActiveNavItem(
  active: string,
  items: NavItem[],
  defaultItem?: NavItem,
) {
  return items.find((item) => getIsNavItemActive(active, item)) || defaultItem;
}

export const whiteListBrandIds = [
  'e634dc99-c046-4bf0-ab4f-77e26462e971',
  '3539ef83-4473-49eb-b8d8-a9658ccfe95d',
  '6d0a3ba7-4d56-4d32-aba4-2e22a8fa130e',
  '40f56961-f7be-451d-a59d-72afd86ab4ea',
];

export const isFeatureVisible = (brandId: string, environment: string) => {
  if (environment?.toLowerCase() !== 'production') return true;
  const isBrandWhitelisted = whiteListBrandIds.find((item) => item === brandId);
  return !!isBrandWhitelisted;
};

export const resetFetcher = (fetcher: FetcherWithComponents<any>) => {
  fetcher.submit({}, {action: '/reset-fetcher', method: 'post'});
};
