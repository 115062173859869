import {FC, useCallback, useState, useEffect} from 'react';
import {useLoaderData} from '@remix-run/react';
import {ChevronUp, ChevronDown} from 'lucide-react';
import {TextInput, Label, ToggleSwitch} from 'flowbite-react';

import {AffiliateSubType} from '~/gql/generated';
import {AnalyticsEvent} from '~/modules/Common/hooks/analyticsEvent';
import {Button} from '~/modules/Common/components/Button';
import {ExternalLinkIcon} from '@heroicons/react/outline';
import {GeneralError} from '~/modules/Brand/Onboarding/GeneralError';
import {GenerateLinkShortCuts} from './GenerateLinkShortCuts';
import {MergedProduct} from '../context/types';
import {TimedToast} from '~/modules/Common/components/TimedToast';
import {useCreateLink} from '../hooks/useCreateLink';
import {useExpert} from '~/modules/Common/hooks/useExpert';
import {useExpertProductIds} from '../hooks/useExpertProductIds';
import {useProducts} from '../hooks/useProducts';
import {useRudderStack} from '~/modules/Common/hooks/useRudderstack';
import ViewProductLinksModal from '~/modules/Expert/components/ViewProductLinksModal';
import CreateAmazonAffiliateLinkModal from '~/modules/Expert/components/CreateAmazonAffiliateLinkModal';
import {BrandType} from '~/modules/Product/components/ProductBrandBadge';
import {Text} from '~/modules/Common/components/Text';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';

interface GenerateLinkControlsProps {
  product: MergedProduct;
  isExternalLinkFirst?: boolean;
  isExternalVisible?: boolean;
  shouldAddProduct: boolean;
  copyButtonColor?:
    | 'expert'
    | 'dark'
    | 'light'
    | 'white'
    | 'purple'
    | 'secondary'
    | undefined;
  source?: 'default' | 'recommendation';
  affiliateSubType?: AffiliateSubType;
  onLinkCreated?: (product: MergedProduct) => void;
  trackingData?: Record<string, string | number | boolean>;
  createLinkClassname?: string;
}

const FormInputIds = {
  primaryTrackingId: 'primaryTrackingId',
  subTrackingId: 'subTrackingId',
  amzAssociateTag: 'amzAssociateTag',
  sourceName: 'sourceName',
};

export const GenerateLinkControls: FC<GenerateLinkControlsProps> = ({
  product,
  isExternalLinkFirst = false,
  isExternalVisible = true,
  shouldAddProduct,
  copyButtonColor,
  source = 'default',
  affiliateSubType = AffiliateSubType.Amazon,
  onLinkCreated,
  trackingData = {},
  createLinkClassname = '',
}) => {
  const {trackEvent} = useRudderStack();
  const {setProduct} = useProducts();
  const {expertName} = useExpert();
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {expertIds, setIds} = useExpertProductIds();
  const [shortcutState, setShortcutState] = useState<{
    product: MergedProduct | null;
    isVisible: boolean;
  }>({
    product: null,
    isVisible: false,
  });
  const [isViewLinkModalOpen, setViewLinkModalOpen] = useState<boolean>(false);
  const [isCreateLinkModalOpen, setCreateLinkModalOpen] =
    useState<boolean>(false);
  const [showAdvancedOption, setShowAdvancedOption] = useState(false);
  const [isAmazonIdVisible, setIsAmazonIdVisible] = useState(false);
  const [formValues, setFormValues] = useState({
    [FormInputIds.primaryTrackingId]: '' as string,
    [FormInputIds.subTrackingId]: '' as string,
    [FormInputIds.sourceName]: '' as string,
    [FormInputIds.amzAssociateTag]: '' as string,
  });
  const [errState, setErrState] = useState({
    [FormInputIds.amzAssociateTag]: false,
    [FormInputIds.primaryTrackingId]: false,
    [FormInputIds.subTrackingId]: false,
  });
  const isAffiliateBrand = product.brandType === BrandType.AFFILIATE;
  const isValidData = !Object.values(errState).find((item) => item);

  const {createLink} = useCreateLink({
    affiliateSubType,
    hasProducts: expertIds.length > 0,
    source,
    trackingData: formValues,
  });
  const data = useLoaderData();
  const amazonSettingLink = useExpertLink('/my/amazonsettings');
  const copyLink = useCallback(() => {
    const amazonLinkIndex = product.viewAllLinks?.findIndex(
      (item) => item?.linkType?.toLowerCase() === 'amazon',
    );
    const waywardLinkIndex = product.viewAllLinks?.findIndex(
      (item) => item?.linkType?.toLowerCase() === 'pdp',
    );
    if (Number(amazonLinkIndex) > -1 || Number(waywardLinkIndex) > -1) {
      const link = product.viewAllLinks?.length
        ? product.viewAllLinks[
            Number(amazonLinkIndex) > -1
              ? Number(amazonLinkIndex)
              : Number(waywardLinkIndex)
          ]?.shortlinkUrl?.toString()
        : null;
      navigator.clipboard.writeText(link || '');
      setShowToast(true);
      trackEvent(AnalyticsEvent.creatorPortalCopyButtonClick, {
        productLink: link,
        productName: product.name,
        productId: product.id,
        expertName,
      });
    }
  }, [expertName, product.id, product.name, product.viewAllLinks, trackEvent]);

  const handleAdvancedOption = () => {
    setShowAdvancedOption((prev) => !prev);
  };

  const onLinkCreateSuccess = useCallback(
    (productToLink: MergedProduct) => {
      productToLink.isVisibleInStore = shouldAddProduct
        ? true
        : productToLink.isVisibleInStore;
      if (typeof onLinkCreated === 'function') {
        onLinkCreated(productToLink);
      }
    },
    [onLinkCreated, shouldAddProduct],
  );

  const generateLink = useCallback(async () => {
    setIsLoading(true);
    const {success, error, product: nextProduct} = await createLink(product);
    setProduct(nextProduct);
    setError(error);

    if (success && nextProduct) {
      onLinkCreateSuccess(nextProduct);
      setIds([nextProduct.id]);
    }
    setIsLoading(false);
  }, [createLink, onLinkCreateSuccess, product, setIds, setProduct]);

  const handleViewLinksModalVisibility = () => {
    setViewLinkModalOpen((prev) => !prev);
  };

  const handleCreateLinkModalVisibility = () => {
    setCreateLinkModalOpen((prev) => !prev);
  };

  const handleChange = (event: any) => {
    const regex = /^[a-zA-Z0-9-]{1,30}$/;
    const {id, value} = event.target;
    if (FormInputIds.sourceName !== id) {
      const result = value.match(regex);
      setErrState((prev) => ({...prev, [id]: !result?.length}));
    }
    setFormValues((prev) => ({...prev, [id]: value}));
  };

  const handleToggleSwitch = () => {
    setIsAmazonIdVisible((prev) => !prev);
  };

  useEffect(() => {
    if (isAmazonIdVisible && !formValues[FormInputIds.amzAssociateTag]) {
      setErrState((prev) => ({
        ...prev,
        [FormInputIds.amzAssociateTag]: true,
      }));
    } else if (!isAmazonIdVisible) {
      setErrState((prev) => ({
        ...prev,
        [FormInputIds.amzAssociateTag]: false,
      }));
    }
  }, [isAmazonIdVisible, formValues]);

  // const createLinkButtonDisabled = () => {
  //   if (!isLoading && isAffiliateBrand && !data.expert.amzAssociateTag) {
  //     return true;
  //   } else if (
  //     !isLoading &&
  //     !isAffiliateBrand &&
  //     !data.expert.amzAssociateTag
  //   ) {
  //     return isValidData;
  //   } else return isAmazonIdVisible && !isValidData;
  // };

  return (
    <>
      {product.viewAllLinks?.length ? (
        <>
          <div className="center-row gap-1 md:gap-2">
            {isExternalLinkFirst && isExternalVisible && (
              <ExternalCallout product={product} />
            )}
            {shouldAddProduct ? (
              <Button
                color={copyButtonColor || 'dark'}
                onClick={handleViewLinksModalVisibility}
                className="flex-auto whitespace-nowrap"
              >
                View {product.viewAllLinks?.length} links
              </Button>
            ) : (
              <Button
                color={copyButtonColor || 'dark'}
                size="sm"
                onClick={copyLink}
                className="flex-auto whitespace-nowrap !px-0.5 !py-1"
                type="button"
              >
                Copy
              </Button>
            )}
            <Button
              color="light"
              className="md:hidden !p-1"
              onClick={() => {
                navigator.share({
                  text: product.name || '',
                  url: product.shortlink || '',
                });
                trackEvent(AnalyticsEvent.creatorPortalShareButtonClick, {
                  productLink: product.shortlink,
                  productName: product.name,
                  productId: product.id,
                  expertName,
                });
              }}
            >
              Share
            </Button>
            {!isExternalLinkFirst && isExternalVisible && (
              <ExternalCallout product={product} />
            )}
          </div>
          <TimedToast
            message="Link copied to clipboard"
            isSuccess={showToast}
            parentSetter={() => setShowToast(false)}
          />
        </>
      ) : (
        <div>
          {!isAffiliateBrand && (
            <div
              className="flex justify-between my-3 cursor-pointer"
              onClick={handleAdvancedOption}
              aria-hidden="true"
            >
              <div className="text-sm">Advanced Options</div>
              {showAdvancedOption ? (
                <ChevronUp size={16} />
              ) : (
                <ChevronDown size={16} />
              )}
            </div>
          )}
          {showAdvancedOption && (
            <div>
              {/* {!data.expert?.amzAssociateTag && !isAffiliateBrand && (
                <Text
                  className="text-black text-left my-4"
                  size="subtext"
                  as="p"
                >
                  <a
                    href={amazonSettingLink}
                    className="text-purple-800 underline underline-offset-4"
                  >
                    Add your Amazon Associate ID
                  </a>
                  &nbsp; to receive additional commissions.
                </Text>
              )} */}
              <div className="flex max-w-md flex-col gap-4">
                {/* <div className="flex justify-between items-center">
                  <div className="text-sm font-medium">
                    Use your default Associate ID
                  </div>
                  <ToggleSwitch
                    checked={!isAmazonIdVisible}
                    label=""
                    onChange={handleToggleSwitch}
                  />
                </div> */}
                {isAmazonIdVisible && (
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor={FormInputIds.amzAssociateTag}
                        value="Amazon Associate ID"
                      />
                    </div>
                    <TextInput
                      id={FormInputIds.amzAssociateTag}
                      type="text"
                      required
                      onChange={handleChange}
                      value={formValues[FormInputIds.amzAssociateTag]}
                      color={
                        errState[FormInputIds.amzAssociateTag] ? 'failure' : ''
                      }
                      helperText={
                        <>
                          {errState[FormInputIds.amzAssociateTag] ? (
                            <span>
                              Please enter a value (or toggle back to use your
                              default Amazon ID)
                            </span>
                          ) : (
                            <></>
                          )}
                        </>
                      }
                    />
                  </div>
                )}
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor={FormInputIds.primaryTrackingId}
                      value="Primary Tracking ID"
                    />
                  </div>
                  <TextInput
                    id={FormInputIds.primaryTrackingId}
                    type="text"
                    required
                    onChange={handleChange}
                    value={formValues[FormInputIds.primaryTrackingId]}
                    color={
                      errState[FormInputIds.primaryTrackingId] ? 'failure' : ''
                    }
                    helperText={
                      <>
                        {errState[FormInputIds.primaryTrackingId] ? (
                          <span>Please enter a valid tracking id</span>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor={FormInputIds.subTrackingId}
                      value="Sub Tracking ID (Optional)"
                    />
                  </div>
                  <TextInput
                    id={FormInputIds.subTrackingId}
                    type="text"
                    onChange={handleChange}
                    value={formValues[FormInputIds.subTrackingId]}
                    color={
                      errState[FormInputIds.subTrackingId] ? 'failure' : ''
                    }
                    helperText={
                      <>
                        {errState[FormInputIds.subTrackingId] ? (
                          <span>Please enter a valid sub tracking id</span>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor={FormInputIds.sourceName}
                      value="Internal Display Name (Optional)"
                    />
                  </div>
                  <TextInput
                    id={FormInputIds.sourceName}
                    type="text"
                    onChange={handleChange}
                    value={formValues[FormInputIds.sourceName]}
                  />
                </div>
              </div>
            </div>
          )}
          <Button
            className={`!p-1 ${createLinkClassname} ${
              showAdvancedOption ? 'mt-6' : ''
            }`}
            size="sm"
            onClick={generateLink}
            disabled={
              isAffiliateBrand
                ? !data.expert?.amzAssociateTag
                : isLoading || !isValidData
            }
            fullSized
            title="Create a commissionable short link"
            isLoading={isLoading}
            type="button"
          >
            Create Link
          </Button>
        </div>
      )}
      <GeneralError error={error} className="mt-2 !py-1" />
      {shortcutState.product && shortcutState.isVisible && (
        <GenerateLinkShortCuts
          product={shortcutState.product}
          onClose={() => {
            setProduct(shortcutState.product);
            setShortcutState({
              product: null,
              isVisible: false,
            });
          }}
          source={source}
        />
      )}
      {isViewLinkModalOpen && (
        <ViewProductLinksModal
          closeModal={handleViewLinksModalVisibility}
          productId={product.id}
          openCreateAffiliateModal={handleCreateLinkModalVisibility}
          brandType={product.brandType || ''}
        />
      )}
      {isCreateLinkModalOpen && (
        <CreateAmazonAffiliateLinkModal
          closeModal={handleCreateLinkModalVisibility}
          productId={product.id}
          isAffiliateProduct={product.brandType === BrandType.AFFILIATE}
        />
      )}
    </>
  );
};

export const ExternalCallout: FC<{
  product: MergedProduct;
  className?: string;
}> = ({product, className = ''}) => (
  <Button
    className={`${className} !py-1`}
    title="Open in new tab"
    color="white"
    icon={<ExternalLinkIcon height={20} width={20} />}
    href={product.shortlink}
    target="_blank"
    style={{
      backgroundImage:
        'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%)',
    }}
  />
);
